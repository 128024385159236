import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";

import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { getUserLanguage } from "../utils";
const userLang = getUserLanguage();
const lang = userLang.language;
const langPack = userLang.languagePackage;
const AboutPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title="Punitech GmbH – Über Uns"
                    content="Punitech GmbH, entwicklung, React JS, Java, Typescript, software, web, Digitalisierung  "
                />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={langPack.about_us}
                        excerpt={langPack.about_us_content}
                        image="./images/banner/1.png"
                    />
                    <AboutContainer />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
