import { NavLink } from "react-router-dom";
import * as React from "react";
import { getUserLanguage } from "../../../utils";

const MainMenu = () => {
    let langPackage = undefined;
    const storedLanguage =
        sessionStorage.getItem("lang") !== null
            ? sessionStorage.getItem("lang")
            : "de";
    const [language, setLanguage] = React.useState(storedLanguage);
    const handleChange = (event) => {
        const lang = event.target.value;
        setLanguage(lang);
        sessionStorage.setItem("lang", lang);
        langPackage = getUserLanguage().languagePackage;

        window.location.reload();
    };
    langPackage = getUserLanguage().languagePackage;
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                <li>
                    <NavLink exact to="/">
                        {langPackage?.main_menu?.start}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/service"}>
                        {langPackage?.main_menu?.services}
                    </NavLink>
                    {/** 
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/service"}
                            >
                                Service
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                single service
                            </NavLink>
                        </li>
                    </ul>*/}
                </li>

                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}>
                        {langPackage?.main_menu?.about_us}
                    </NavLink>
                </li>

                {/*<li>
                    <NavLink to={process.env.PUBLIC_URL + ""}>Karriere</NavLink>
                </li>*/}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                        {langPackage?.main_menu?.contact}
                    </NavLink>
                </li>
                {/*<li>
                    <Select
                        className="language-select-label"
                        value={language}
                        defaultValue={"de"}
                        onChange={handleChange}
                        variant={"standard"}
                        displayEmpty={false}
                    >
                        <MenuItem value={"de"}>
                            <Flag className="language-flag" code="de" />
                        </MenuItem>
                        <MenuItem value={"en"}>
                            <Flag className="language-flag" code="us" />
                        </MenuItem>
                    </Select>
            </li>*/}
            </ul>
        </nav>
    );
};

export default MainMenu;
