import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../logo";
import ServiceData from "../../../data/service.json";
import { useState, useEffect } from "react";
import { getUserLanguage } from "../../../utils";

const MobileMenu = ({ show, onClose }) => {
    const intialSubMenuState = {
        "blog-menu": { open: false },
        "service-menu": { open: true },
    };

    const [subMenuState, setSubMenuState] = useState(intialSubMenuState);
    const location = useLocation();
    const userLang = getUserLanguage();
    const lang = userLang.language;
    const langPackage = userLang.languagePackage;

    useEffect(() => {
        if (localStorage.getItem("subMenuState")) {
            const subMenuState = JSON.parse(
                localStorage.getItem("subMenuState")
            );
            setSubMenuState(subMenuState);
        }
    }, []);
    const subMenuHandler = (e) => {
        //update the opened sub menu
        let newSubMenu = subMenuState;
        if (subMenuState[e.currentTarget.name].open == false) {
            Object.keys(subMenuState).forEach((subMenu) => {
                if (
                    subMenu != e.currentTarget.name &&
                    subMenuState[subMenu].open == true
                ) {
                    newSubMenu = {
                        ...subMenuState,
                        [subMenu]: {
                            ...subMenuState[subMenu],
                            open: false,
                        },
                    };
                }
                return newSubMenu;
            });
        }
        newSubMenu = {
            ...newSubMenu,
            [e.currentTarget.name]: {
                ...newSubMenu[e.currentTarget.name],
                open: !newSubMenu[e.currentTarget.name].open,
            },
        };
        setSubMenuState(newSubMenu);
        try {
            localStorage.setItem("subMenuState", JSON.stringify(newSubMenu));
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink
                                    exact
                                    to="/"
                                    id="home"
                                    className={`${
                                        "/" == location.pathname
                                            ? "selected"
                                            : ""
                                    }`}
                                >
                                    {langPackage.main_menu.start}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/about"}
                                    id="about"
                                    className={`${
                                        "/about" == location.pathname
                                            ? "selected"
                                            : ""
                                    }`}
                                >
                                    {langPackage.main_menu.about_us}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/service"}
                                    id="/service"
                                    className={`${
                                        "/service" == location.pathname
                                            ? "selected"
                                            : ""
                                    }`}
                                >
                                    {langPackage.main_menu.services}
                                </NavLink>
                                <button
                                    className="menu-expand"
                                    name="service-menu"
                                    onClick={subMenuHandler}
                                ></button>
                                <ul
                                    className={
                                        subMenuState["service-menu"].open
                                            ? "d-block"
                                            : "d-none"
                                    }
                                >
                                    {ServiceData?.map((service, key) => {
                                        return (
                                            <li key={key}>
                                                <NavLink
                                                    onClick={onClose}
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        "/service-details/" +
                                                        service.id
                                                    }
                                                    id={service.name}
                                                    className={`${
                                                        "/service-details/" +
                                                            service.id ==
                                                        location.pathname
                                                            ? "selected"
                                                            : ""
                                                    }`}
                                                >
                                                    {service.title[lang]}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/contact"}
                                    id="contact"
                                    className={`${
                                        "/contact" == location.pathname
                                            ? "selected"
                                            : ""
                                    }`}
                                >
                                    {langPackage.main_menu.contact}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
