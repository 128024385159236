import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getUserLanguage, slugify } from "../../utils";
import { dynamicIcon } from "../iconRepo/IconRepo";

const userLang = getUserLanguage();
const lang = userLang.language;

const ServiceBox = ({ data }) => {
    return (
        <div className="service-media">
            <div className="service-media-body">
                <h4 className="title">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/service-details/${slugify(data.id)}`
                        }
                    >
                        {data.icon ? dynamicIcon[data.icon] : ""}{" "}
                        {data.title[lang]}
                    </Link>
                </h4>
                <p>{data.excerpt[lang]}</p>
            </div>
        </div>
    );
};

ServiceBox.propTypes = {
    data: PropTypes.object,
};

export default ServiceBox;
