import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import { getUserLanguage } from "../../utils";

const userLanguage = getUserLanguage();
const lang = userLanguage.language;
const languagePackage = userLanguage.languagePackage;

const AboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={languagePackage.about_us}
                                title={languagePackage.about_us_title}
                            />
                            <span className="date-meta">
                                {languagePackage.about_us_start_year}{" "}
                                <span className="hr"></span>
                            </span>
                            <p className="mb-5">
                                {languagePackage.about_us_content}
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div
                                data-depth="0.2"
                                className="about_us_image_container"
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/service/7.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
