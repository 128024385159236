import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";

import PageBanner from "../containers/global/page-banner";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { getUserLanguage } from "../utils";
const userLang = getUserLanguage();
const lang = userLang.language;
const langPack = userLang.languagePackage;

const ServicePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Punitech GmbH – Service" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={langPack.our_services}
                        excerpt={langPack.services_main_description}
                        image="./images/service/2.png"
                    />
                    <ServiceListContainer withTitleSection={false} />
                    <BrandContainer />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
