import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { getUserLanguage } from "../../utils";
const langPack = getUserLanguage().languagePackage;
const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                action="https://getform.io/f/377f3ff0-deee-4577-bb3e-dc1e56b7fb83"
                method="POST"
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder={langPack.contact_form_name + " *"}
                        ref={register({
                            required: langPack.contatc_form_mandatory_field,
                        })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        placeholder={langPack.contact_form_company_name}
                    />
                    {errors.company && <p>{errors.company.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={langPack.contact_form_email + " *"}
                        ref={register({
                            required: langPack.contact_form_email_mandatory_msg,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message:
                                    langPack.contact_form_invalid_email_msg,
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder={langPack.contact_form_your_question}
                        ref={register({
                            required: langPack.contatc_form_mandatory_field,
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text={langPack.contact_form_thank_you}
                    >
                        {langPack.contact_form_send}
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
