import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    flatDeep,
    slugify,
    containsObject,
    getUserLanguage,
} from "../../../utils";
import { dynamicIcon } from "../../iconRepo/IconRepo";
const userLang = getUserLanguage();
const lang = userLang.language;
const ServiceCate = ({ data }) => {
    const cats = data.map((item) => {
        return item.categories;
    });
    let singleCatArray = flatDeep(cats).filter((item) => Boolean(item));
    let categories = [];
    singleCatArray.forEach((cat) => {
        const obj = {
            title: cat.trim(),
            slug: slugify(cat),
            count: 1,
        };
        const objIndex = containsObject(obj, categories);
        if (objIndex !== -1) {
            const prevCount = categories[objIndex].count;
            categories[objIndex] = {
                title: cat.trim(),
                slug: slugify(cat),
                count: prevCount + 1,
            };
        } else {
            categories.push(obj);
        }
    });
    return (
        <div className="sidbar-menu">
            <ul>
                {data.map((single, i) => {
                    return (
                        <li key={i}>
                            <NavLink
                                activeClassName="active"
                                to={
                                    process.env.PUBLIC_URL +
                                    `/service-details/${slugify(single.id)}`
                                }
                            >
                                <div className="service-cate__service-icon-container">
                                    {single.icon
                                        ? dynamicIcon[single.icon]
                                        : ""}
                                </div>
                                {single.title[lang]}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

ServiceCate.propTypes = {
    data: PropTypes.array,
};

export default ServiceCate;
