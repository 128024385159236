import React, { useEffect, useState, useRef } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ScrollToTop = (props) => {
    const [stick, setStick] = useState(false);
    const componentMounted = useRef(true);
    const onClickHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        var position = window.pageYOffset;

        const scrollHandler = () => {
            let scrollPos = window.pageYOffset;
            if (componentMounted.current) {
                if (scrollPos < 200) {
                    setStick(false);
                } else if (scrollPos < position) {
                    setStick(true);
                } else {
                    setStick(false);
                }
            }
            position = scrollPos;
        };

        window.addEventListener("scroll", function () {
            scrollHandler();
        });
        return () => {
            window.removeEventListener("scroll", function () {
                scrollHandler();
            });
            componentMounted.current = false;
        };
    }, [stick]);

    return (
        <button
            type="button"
            className={`scroll-to-top ${stick ? "show" : ""}`}
            onClick={onClickHandler}
            {...props}
        >
            <ArrowUpwardIcon></ArrowUpwardIcon>
        </button>
    );
};

export default ScrollToTop;
