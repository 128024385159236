import React from "react";
import Logo from "../../components/logo";
import { SocialIcon } from "react-social-icons";
import { getUserLanguage } from "../../utils";
const langPack = getUserLanguage().languagePackage;
const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <div className="container">
                    <div className="row mb-n7 footer-text-container">
                        <div className="col-lg-6 col-sm-3 mb-2 mt-2">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-6"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                />
                                <ul
                                    className="footer-social-links"
                                    style={{ marginLeft: "10px" }}
                                >
                                    <li>
                                        <SocialIcon url="https://de.linkedin.com/company/punitech-gmbh" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-3 mb-2">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {langPack.contact_in_germany + "  "}
                                </h4>
                                <p>
                                    Kirrlacherstr. 23
                                    <br />
                                    68753 Waghäusel
                                    <br />
                                    Deutschland
                                </p>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+4917623663693"
                                        >
                                            +49 176 23663693
                                        </a>
                                        <br />
                                        <a
                                            className="address-link"
                                            href="tel:+4917635260384"
                                        >
                                            +49 176 35260384
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@punitech.de"
                                        >
                                            info@punitech.de
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
