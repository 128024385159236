import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";
import { getUserLanguage } from "../../utils";
const language = getUserLanguage();
const lang = language.language;
const languagetPackage = language.languagePackage;
const Intro = ({ data }) => {
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row align-items-center">
                    <div className="hero-slide-content">
                        <h4
                            className="title animated"
                            dangerouslySetInnerHTML={{
                                __html: data.title[lang],
                            }}
                        ></h4>
                        <Button
                            classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                            text={languagetPackage.get_in_touch_button_text}
                            path="/contact"
                        />
                    </div>
                    <div
                        className="hero-img scene mt-10 mt-lg-0"
                        id="scene"
                        ref={sceneEl}
                    >
                        <div data-depth="0.2">
                            <img
                                className="animated"
                                src={`${process.env.PUBLIC_URL}/${data.image}`}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
