import ForumIcon from "@mui/icons-material/Forum";
import CodeIcon from "@mui/icons-material/Code";
import StorageIcon from "@mui/icons-material/Storage";
import DevicesIcon from "@mui/icons-material/Devices";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

export const dynamicIcon = {
    ForumIcon: <ForumIcon />,
    CodeIcon: <CodeIcon />,
    StorageIcon: <StorageIcon />,
    DevicesIcon: <DevicesIcon />,
    PeopleAltIcon: <PeopleAltIcon />,
};
