import PropTypes from "prop-types";

const Customer = ({ data }) => {
    return (
        <div className="single-brand">
            <img
                src={process.env.PUBLIC_URL + data.image}
                alt="customer logo"
                width={data.width}
                height={data.height}
            />
        </div>
    );
};

Customer.propTypes = {
    data: PropTypes.object,
};

export default Customer;
