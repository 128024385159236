import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";
import { getUserLanguage } from "../../../utils";

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);
    const language = getUserLanguage();
    const langPackage = language.languagePackage;

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={langPackage.about_us}
                                title={langPackage.about_us_title}
                            />
                            <span className="date-meta">
                                {langPackage.about_us_start_year}{" "}
                                <span className="hr"></span>
                            </span>
                            <p className="mb-5">
                                {langPackage.about_us_content}
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div
                                data-depth="0.2"
                                className="about_us_image_container"
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/service/7.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
    />*/}
        </div>
    );
};

export default HomeAboutContainer;
