import React from "react";
import PropTypes from "prop-types";
import PageBannerImage from "../../../components/page-banner-image";
import PageTitle from "../../../components/page-title";

const PageBanner = ({ title, excerpt, image }) => {
    return (
        <div className="banner-section position-relative banner-container">
            <div className="banner-shape" alt="shape" />
            <div className="container">
                <div className="row align-items-center">
                    <PageTitle title={title} excerpt={excerpt} />
                    <PageBannerImage image={image} />
                </div>
            </div>
        </div>
    );
};

PageBanner.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
    image: PropTypes.string,
};

export default PageBanner;
