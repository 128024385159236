import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageBanner from "../containers/global/page-banner";

const ImprintPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Punitech GmbH – Service" content="Impressum" />
                <div className="wrapper">
                    <Header />

                    <PageBanner
                        title="Impressum"
                        image="./images/service/media.png"
                    />

                    <div
                        className="container content box-middle-container full-screen-size"
                        data-sub-height="0"
                    >
                        <div className="row">
                            <div className="col-md-12 box-middle">
                                <div>
                                    <p>
                                        Punitech GmbH
                                        <br />
                                        Kirrlacherstr. 23
                                        <br />
                                        68753 Waghäusel
                                    </p>
                                    <p>
                                        Telefon: +49-176-23663693
                                        <br />
                                        E-Mail:{" "}
                                        <a href="mailto:info@punitech.de">
                                            info@punitech.de
                                        </a>
                                        <br />
                                    </p>
                                    <p>
                                        <strong>Vertreten durch:</strong>
                                        <br />
                                        Dominik Bonsch
                                    </p>
                                    <p>
                                        <strong>Registereintrag:</strong>
                                        <br />
                                        Eingetragen im Handelsregister.
                                        <br />
                                        Registergericht: Mannheim
                                        <br />
                                        Registernummer: HRB 719692
                                    </p>
                                    <p>
                                        <strong>Umsatzsteuer-ID: </strong>
                                        <br />
                                        Umsatzsteuer-Identifikationsnummer nach
                                        §27a Umsatzsteuergesetz:
                                        <br />
                                        USt.-ID: DE295132819
                                    </p>
                                    <br />
                                    <br />
                                    <h2>Disclaimer – rechtliche Hinweise</h2>
                                    § 1 Warnhinweis zu Inhalten
                                    <br />
                                    Die kostenlosen und frei zugänglichen
                                    Inhalte dieser Webseite wurden mit
                                    größtmöglicher Sorgfalt erstellt. Der
                                    Anbieter dieser Webseite übernimmt jedoch
                                    keine Gewähr für die Richtigkeit und
                                    Aktualität der bereitgestellten kostenlosen
                                    und frei zugänglichen journalistischen
                                    Ratgeber und Nachrichten. Namentlich
                                    gekennzeichnete Beiträge geben die Meinung
                                    des jeweiligen Autors und nicht immer die
                                    Meinung des Anbieters wieder. Allein durch
                                    den Aufruf der kostenlosen und frei
                                    zugänglichen Inhalte kommt keinerlei
                                    Vertragsverhältnis zwischen dem Nutzer und
                                    dem Anbieter zustande, insoweit fehlt es am
                                    Rechtsbindungswillen des Anbieters.
                                    <br />
                                    <br />
                                    § 2 Externe Links
                                    <br />
                                    Diese Website enthält Verknüpfungen zu
                                    Websites Dritter (&quot;externe
                                    Links&quot;). Diese Websites unterliegen der
                                    Haftung der jeweiligen Betreiber. Der
                                    Anbieter hat bei der erstmaligen Verknüpfung
                                    der externen Links die fremden Inhalte
                                    daraufhin überprüft, ob etwaige
                                    Rechtsverstöße bestehen. Zu dem Zeitpunkt
                                    waren keine Rechtsverstöße ersichtlich. Der
                                    Anbieter hat keinerlei Einfluss auf die
                                    aktuelle und zukünftige Gestaltung und auf
                                    die Inhalte der verknüpften Seiten. Das
                                    Setzen von externen Links bedeutet nicht,
                                    dass sich der Anbieter die hinter dem
                                    Verweis oder Link liegenden Inhalte zu Eigen
                                    macht. Eine ständige Kontrolle der externen
                                    Links ist für den Anbieter ohne konkrete
                                    Hinweise auf Rechtsverstöße nicht zumutbar.
                                    Bei Kenntnis von Rechtsverstößen werden
                                    jedoch derartige externe Links unverzüglich
                                    gelöscht.
                                    <br />
                                    <br />
                                    § 3 Urheber- und Leistungsschutzrechte
                                    <br />
                                    Die auf dieser Website veröffentlichten
                                    Inhalte unterliegen dem deutschen Urheber-
                                    und Leistungsschutzrecht. Jede vom deutschen
                                    Urheber- und Leistungsschutzrecht nicht
                                    zugelassene Verwertung bedarf der vorherigen
                                    schriftlichen Zustimmung des Anbieters oder
                                    jeweiligen Rechteinhabers. Dies gilt
                                    insbesondere für Vervielfältigung,
                                    Bearbeitung, Übersetzung, Einspeicherung,
                                    Verarbeitung bzw. Wiedergabe von Inhalten in
                                    Datenbanken oder anderen elektronischen
                                    Medien und Systemen. Inhalte und Rechte
                                    Dritter sind dabei als solche
                                    gekennzeichnet. Die unerlaubte
                                    Vervielfältigung oder Weitergabe einzelner
                                    Inhalte oder kompletter Seiten ist nicht
                                    gestattet und strafbar. Lediglich die
                                    Herstellung von Kopien und Downloads für den
                                    persönlichen, privaten und nicht
                                    kommerziellen Gebrauch ist erlaubt.
                                    <br />
                                    <br />
                                    Die Darstellung dieser Website in fremden
                                    Frames ist nur mit schriftlicher Erlaubnis
                                    zulässig.
                                    <br />
                                    <br />
                                    § 4 Besondere Nutzungsbedingungen
                                    <br />
                                    Soweit besondere Bedingungen für einzelne
                                    Nutzungen dieser Website von den
                                    vorgenannten Paragraphen abweichen, wird an
                                    entsprechender Stelle ausdrücklich darauf
                                    hingewiesen. In diesem Falle gelten im
                                    jeweiligen Einzelfall die besonderen
                                    Nutzungsbedingungen.
                                    <p>
                                        Quelle:{" "}
                                        <a href="https://www.juraforum.de/impressum-generator/">
                                            Impressum Muster von JuraForum.de
                                        </a>
                                    </p>
                                    <hr className="space m" />
                                    <p>
                                        Wir informieren Sie nachfolgend gemäß
                                        den gesetzlichen Vorgaben des
                                        Datenschutzrechts (insb. gemäß BDSG n.F.
                                        und der europäischen
                                        Datenschutz-Grundverordnung ‚DS-GVO‘)
                                        über die Art, den Umfang und Zweck der
                                        Verarbeitung personenbezogener Daten
                                        durch unser Unternehmen. Diese
                                        Datenschutzerklärung gilt auch für
                                        unsere Websites und
                                        Sozial-Media-Profile. Bezüglich der
                                        Definition von Begriffen wie etwa
                                        „personenbezogene Daten“ oder
                                        „Verarbeitung“ verweisen wir auf Art. 4
                                        DS-GVO.
                                    </p>
                                    <strong>
                                        Name und Kontaktdaten des / der
                                        Verantwortlichen
                                    </strong>
                                    <br />
                                    Unser/e Verantwortliche/r (nachfolgend
                                    „Verantwortlicher“) i.S.d. Art. 4 Zif. 7
                                    DS-GVO ist:
                                    <br />
                                    <br />
                                    Punitech GmbH
                                    <br />
                                    Kirrlacherstr. 23
                                    <br />
                                    68753 Waghäusel Deutschland
                                    <br />
                                    Geschäftsführer Dominik Bonsch
                                    <br />
                                    Handelsregister/Nr.: HRB 719692
                                    <br />
                                    Registergericht: Mannheim
                                    <br />
                                    E-Mail-Adresse: contact@punitech.com
                                    <br />
                                    <br />
                                    <strong>
                                        Datenarten, Zwecke der Verarbeitung und
                                        Kategorien betroffener Personen
                                    </strong>
                                    <br />
                                    <p>
                                        Nachfolgend informieren wir Sie über
                                        Art, Umfang und Zweck der Erhebung,
                                        Verarbeitung und Nutzung
                                        personenbezogener Daten.
                                    </p>
                                    <strong>
                                        1. Arten der Daten, die wir verarbeiten
                                    </strong>
                                    <br />
                                    Nutzungsdaten (Zugriffszeiten, besuchte
                                    Websites etc.), Kommunikationsdaten
                                    (IP-Adresse etc.), <br />
                                    <br />
                                    <strong>
                                        2. Zwecke der Verarbeitung nach Art. 13
                                        Abs. 1 c) DS-GVO
                                    </strong>
                                    <br />
                                    Beweiszwecke / Beweissicherung, Leichten
                                    Zugang zur Website ermöglichen, Optimierung
                                    und statistische Auswertung unserer Dienste,
                                    Wirtschaftlicher Betrieb der Werbung und
                                    Website, Marketing / Vertrieb / Werbung,
                                    Erstellung von Statistiken, Abwicklung eines
                                    Bewerberverfahrens, Maßnahmen der
                                    Sicherheit, <br />
                                    <br />
                                    <strong>
                                        3. Kategorien der betroffenen Personen
                                        nach Art. 13 Abs. 1 e) DS-GVO
                                    </strong>
                                    <br />
                                    Besucher/Nutzer der Website, <br />
                                    <br />
                                    <p>
                                        Die betroffenen Personen werden
                                        zusammenfassend als „Nutzer“ bezeichnet.
                                    </p>
                                    <br />
                                    <strong>
                                        Rechtsgrundlagen der Verarbeitung
                                        personenbezogener Daten
                                    </strong>
                                    <p>
                                        Nachfolgend Informieren wir Sie über die
                                        Rechtsgrundlagen der Verarbeitung
                                        personenbezogener Daten:
                                    </p>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wenn wir Ihre Einwilligung für die
                                            Verarbeitung personenbezogenen Daten
                                            eingeholt haben, ist Art. 6 Abs. 1
                                            S. 1 lit. a) DS-GVO Rechtsgrundlage.
                                        </li>
                                        <li>
                                            Ist die Verarbeitung zur Erfüllung
                                            eines Vertrags oder zur Durchführung
                                            vorvertraglicher Maßnahmen
                                            erforderlich, die auf Ihre Anfrage
                                            hin erfolgen, so ist Art. 6 Abs. 1
                                            S. 1 lit. b) DS-GVO Rechtsgrundlage.
                                        </li>
                                        <li>
                                            Ist die Verarbeitung zur Erfüllung
                                            einer rechtlichen Verpflichtung
                                            erforderlich, der wir unterliegen
                                            (z.B. gesetzliche
                                            Aufbewahrungspflichten), so ist Art.
                                            6 Abs. 1 S. 1 lit. c) DS-GVO
                                            Rechtsgrundlage.
                                        </li>
                                        <li>
                                            Ist die Verarbeitung erforderlich,
                                            um lebenswichtige Interessen der
                                            betroffenen Person oder einer
                                            anderen natürlichen Person zu
                                            schützen, so ist Art. 6 Abs. 1 S. 1
                                            lit. d) DS-GVO Rechtsgrundlage.
                                        </li>
                                        <li>
                                            Ist die Verarbeitung zur Wahrung
                                            unserer oder der berechtigten
                                            Interessen eines Dritten
                                            erforderlich und überwiegen
                                            diesbezüglich Ihre Interessen oder
                                            Grundrechte und Grundfreiheiten
                                            nicht, so ist Art. 6 Abs. 1 S. 1
                                            lit. f) DS-GVO Rechtsgrundlage.
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>
                                        Weitergabe personenbezogener Daten an
                                        Dritte und Auftragsverarbeiter
                                    </strong>
                                    <p>
                                        Ohne Ihre Einwilligung geben wir
                                        grundsätzlich keine Daten an Dritte
                                        weiter. Sollte dies doch der Fall sein,
                                        dann erfolgt die Weitergabe auf der
                                        Grundlage der zuvor genannten
                                        Rechtsgrundlagen z.B. bei der Weitergabe
                                        von Daten an Online-Paymentanbieter zur
                                        Vertragserfüllung oder aufgrund
                                        gerichtlicher Anordnung oder wegen einer
                                        gesetzlichen Verpflichtung zur
                                        Herausgabe der Daten zum Zwecke der
                                        Strafverfolgung, zur Gefahrenabwehr oder
                                        zur Durchsetzung der Rechte am geistigen
                                        Eigentum.
                                        <br />
                                        Wir setzen zudem Auftragsverarbeiter
                                        (externe Dienstleister z.B. zum
                                        Webhosting unserer Websites und
                                        Datenbanken) zur Verarbeitung Ihrer
                                        Daten ein. Wenn im Rahmen einer
                                        Vereinbarung zur Auftragsverarbeitung an
                                        die Auftragsverarbeiter Daten
                                        weitergegeben werden, erfolgt dies immer
                                        nach Art. 28 DS-GVO. Wir wählen dabei
                                        unsere Auftragsverarbeiter sorgfältig
                                        aus, kontrollieren diese regelmäßig und
                                        haben uns ein Weisungsrecht hinsichtlich
                                        der Daten einräumen lassen. Zudem müssen
                                        die Auftragsverarbeiter geeignete
                                        technische und organisatorische
                                        Maßnahmen getroffen haben und die
                                        Datenschutzvorschriften gem. BDSG n.F.
                                        und DS-GVO einhalten
                                    </p>
                                    <br />
                                    <strong>
                                        Datenübermittlung in Drittstaaten
                                    </strong>
                                    <p>
                                        Durch die Verabschiedung der
                                        europäischen Datenschutz-Grundverordnung
                                        (DS-GVO) wurde eine einheitliche
                                        Grundlage für den Datenschutz in Europa
                                        geschaffen. Ihre Daten werden daher
                                        vorwiegend durch Unternehmen
                                        verarbeitet, für die DS-GVO Anwendung
                                        findet. Sollte doch die Verarbeitung
                                        durch Dienste Dritter außerhalb der
                                        Europäischen Union oder des Europäischen
                                        Wirtschaftsraums stattfinden, so müssen
                                        diese die besonderen Voraussetzungen der
                                        Art. 44 ff. DS-GVO erfüllen. Das
                                        bedeutet, die Verarbeitung erfolgt
                                        aufgrund besonderer Garantien, wie etwa
                                        die von der EU-Kommission offiziell
                                        anerkannte Feststellung eines der EU
                                        entsprechenden Datenschutzniveaus oder
                                        der Beachtung offiziell anerkannter
                                        spezieller vertraglicher
                                        Verpflichtungen, der so genannten
                                        „Standardvertragsklauseln“. Bei
                                        US-Unternehmen erfüllt die Unterwerfung
                                        unter das sog. „Privacy-Shield“, dem
                                        Datenschutzabkommen zwischen der EU und
                                        den USA, diese Voraussetzungen.
                                    </p>
                                    <br />
                                    <strong>
                                        Löschung von Daten und Speicherdauer
                                    </strong>
                                    <p>
                                        Sofern nicht in dieser
                                        Datenschutzerklärung ausdrücklich
                                        angegeben, werden Ihre personenbezogen
                                        Daten gelöscht oder gesperrt, sobald der
                                        Zweck für die Speicherung entfällt, es
                                        sei denn deren weitere Aufbewahrung ist
                                        zu Beweiszwecken erforderlich oder dem
                                        stehen gesetzliche
                                        Aufbewahrungspflichten entgegenstehen.
                                        Darunter fallen etwa handelsrechtliche
                                        Aufbewahrungspflichten von
                                        Geschäftsbriefen nach § 257 Abs. 1 HGB
                                        (6 Jahre) sowie steuerrechtliche
                                        Aufbewahrungspflichten nach § 147 Abs. 1
                                        AO von Belegen (10 Jahre). Wenn die
                                        vorgeschriebene Aufbewahrungsfrist
                                        abläuft, erfolgt eine Sperrung oder
                                        Löschung Ihrer Daten, es sei denn die
                                        Speicherung ist weiterhin für einen
                                        Vertragsabschluss oder zur
                                        Vertragserfüllung erforderlich.
                                    </p>
                                    <br />
                                    <strong>
                                        Bestehen einer automatisierten
                                        Entscheidungsfindung
                                    </strong>
                                    <p>
                                        Wir setzen keine automatische
                                        Entscheidungsfindung oder ein Profiling
                                        ein.
                                    </p>
                                    <br />
                                    <strong>
                                        Bereitstellung unserer Website und
                                        Erstellung von Logfiles
                                    </strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wenn Sie unsere Webseite lediglich
                                            informatorisch nutzen (also keine
                                            Registrierung und auch keine
                                            anderweitige Übermittlung von
                                            Informationen), erheben wir nur die
                                            personenbezogenen Daten, die Ihr
                                            Browser an unseren Server
                                            übermittelt. Wenn Sie unsere Website
                                            betrachten möchten, erheben wir die
                                            folgenden Daten: • IP-Adresse;
                                            <br />
                                            • Internet-Service-Provider des
                                            Nutzers;
                                            <br />
                                            • Datum und Uhrzeit des Abrufs;
                                            <br />
                                            • Browsertyp;
                                            <br />
                                            • Sprache und Browser-Version;
                                            <br />
                                            • Inhalt des Abrufs;
                                            <br />
                                            • Zeitzone;
                                            <br />
                                            • Zugriffsstatus/HTTP-Statuscode;
                                            <br />
                                            • Datenmenge;
                                            <br />
                                            • Websites, von denen die
                                            Anforderung kommt;
                                            <br />
                                            • Betriebssystem.
                                            <br />
                                            Eine Speicherung dieser Daten
                                            zusammen mit anderen
                                            personenbezogenen Daten von Ihnen
                                            findet nicht statt.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Diese Daten dienen dem Zweck der
                                            nutzerfreundlichen, funktionsfähigen
                                            und sicheren Auslieferung unserer
                                            Website an Sie mit Funktionen und
                                            Inhalten sowie deren Optimierung und
                                            statistischen Auswertung.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Rechtsgrundlage hierfür ist unser in
                                            den obigen Zwecken auch liegendes
                                            berechtigtes Interesse an der
                                            Datenverarbeitung nach Art. 6 Abs. 1
                                            S.1 lit. f) DS-GVO.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Wir speichern aus Sicherheitsgründen
                                            diese Daten in Server-Logfiles für
                                            die Speicherdauer von 30 Tagen. Nach
                                            Ablauf dieser Frist werden diese
                                            automatisch gelöscht, es sei denn
                                            wir benötigen deren Aufbewahrung zu
                                            Beweiszwecken bei Angriffen auf die
                                            Serverinfrastruktur oder anderen
                                            Rechtsverletzungen.
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>Cookies</strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wir verwenden sog. Cookies bei Ihrem
                                            Besuch unserer Website. Cookies sind
                                            kleine Textdateien, die Ihr
                                            Internet-Browser auf Ihrem Rechner
                                            ablegt und speichert. Wenn Sie
                                            unsere Website erneut aufrufen,
                                            geben diese Cookies Informationen
                                            ab, um Sie automatisch
                                            wiederzuerkennen. Die so erlangten
                                            Informationen dienen dem Zweck,
                                            unsere Webangebote technisch und
                                            wirtschaftlich zu optimieren und
                                            Ihnen einen leichteren und sicheren
                                            Zugang auf unsere Website zu
                                            ermöglichen. Wir informieren Sie
                                            dazu beim Aufruf unserer Website
                                            mittels eines Hinweises auf unsere
                                            Datenschutzerklärung über die
                                            Verwendung von Cookies zu den zuvor
                                            genannten Zwecken und wie Sie dieser
                                            widersprechen bzw. deren Speicherung
                                            verhindern können („Opt-out“).
                                            Unsere Website nutzt
                                            Session-Cookies, persistente Cookies
                                            und Cookies von Drittanbietern:
                                            <br />
                                            <br />
                                            <strong>
                                                • Session-Cookies:
                                            </strong>{" "}
                                            Wir verwenden sog. Cookies zum
                                            Wiedererkennen mehrfacher Nutzung
                                            eines Angebots durch denselben
                                            Nutzer (z.B. wenn Sie sich
                                            eingeloggt haben zur Feststellung
                                            Ihres Login-Status). Wenn Sie unsere
                                            Seite erneut aufrufen, geben diese
                                            Cookies Informationen ab, um Sie
                                            automatisch wiederzuerkennen. Die so
                                            erlangten Informationen dienen dazu,
                                            unsere Angebote zu optimieren und
                                            Ihnen einen leichteren Zugang auf
                                            unsere Seite zu ermöglichen. Wenn
                                            Sie den Browser schließen oder Sie
                                            sich ausloggen, werden die
                                            Session-Cookies gelöscht.
                                            <br />
                                            <br />
                                            <strong>
                                                • Persistente Cookies:
                                            </strong>{" "}
                                            Diese werden automatisiert nach
                                            einer vorgegebenen Dauer gelöscht,
                                            die sich je nach Cookie
                                            unterscheiden kann. In den
                                            Sicherheitseinstellungen Ihres
                                            Browsers können Sie die Cookies
                                            jederzeit löschen.
                                            <br />
                                            <br />
                                            <strong>
                                                • Cookies von Drittanbietern
                                                (Third-Party-Cookies):
                                            </strong>{" "}
                                            Entsprechend Ihren Wünschen können
                                            Sie können Ihre Browser-Einstellung
                                            konfigurieren und z. B. Die Annahme
                                            von Third-Party-Cookies oder allen
                                            Cookies ablehnen. Wir weisen Sie
                                            jedoch an dieser Stelle darauf hin,
                                            dass Sie dann eventuell nicht alle
                                            Funktionen dieser Website nutzen
                                            können. Lesen Sie Näheres zu diesen
                                            Cookies bei den jeweiligen
                                            Datenschutzerklärungen zu den
                                            Drittanbietern.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Rechtsgrundlage dieser Verarbeitung
                                            ist Art. 6 Abs. 1 S. lit. b) DS-GVO,
                                            wenn die Cookies zur
                                            Vertragsanbahnung z.B. bei
                                            Bestellungen gesetzt werden und
                                            ansonsten haben wir ein berechtigtes
                                            Interesse an der effektiven
                                            Funktionalität der Website, so dass
                                            in dem Falle Art. 6 Abs. 1 S. 1 lit.
                                            f) DS-GVO Rechtsgrundlage ist.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>
                                                Widerspruch und „Opt-Out“:
                                            </strong>{" "}
                                            Das Speichern von Cookies auf Ihrer
                                            Festplatte können Sie allgemein
                                            verhindern, indem Sie in Ihren
                                            Browser-Einstellungen „keine Cookies
                                            akzeptieren“ wählen. Dies kann aber
                                            eine Funktionseinschränkung unserer
                                            Angebote zur Folge haben. Sie können
                                            dem Einsatz von Cookies von
                                            Drittanbietern zu Werbezwecken über
                                            ein sog. „Opt-out“ über diese
                                            amerikanische Website (
                                            <a
                                                href="https://optout.aboutads.info"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://optout.aboutads.info
                                            </a>
                                            ) oder diese europäische Website (
                                            <a
                                                href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                http://www.youronlinechoices.com/de/praferenzmanagement/
                                            </a>
                                            ) widersprechen.
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>Google Analytics</strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wir haben das Webseitenanalyse-Tool
                                            „Google Analytics“ (Google Ireland
                                            Limited, Registernr.: 368047, Gordon
                                            House, Barrow Street, Dublin 4,
                                            Irland) auf unserer Website
                                            integriert.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Beim Besuch unserer Website setzt
                                            Google einen Cookie auf Ihren
                                            Computer, um die Benutzung unserer
                                            Website durch Sie analysieren zu
                                            können. Die gewonnenen Daten werden
                                            in die USA übertragen und dort
                                            gespeichert. Falls personenbezogen
                                            Daten in die USA übertragen werden
                                            sollten, bietet die Zertifizierung
                                            Googles gemäß
                                            Privacy-Shield-Abkommen (
                                            <a
                                                href="https://www.privacyshield.gov/EU-US-Framework"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.privacyshield.gov/EU-US-Framework
                                            </a>
                                            ) die Garantie dafür, dass das
                                            europäische Datenschutzrecht
                                            eingehalten wird.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Wir haben die IP-Anonymisierung
                                            „anonymizeIP“ aktiviert, wodurch die
                                            IP-Adressen nur gekürzt
                                            weiterverarbeitet werden. Auf dieser
                                            Webseite wird Ihre IP-Adresse von
                                            Google daher innerhalb von
                                            Mitgliedstaaten der Europäischen
                                            Union oder in anderen
                                            Vertragsstaaten des Abkommens über
                                            den Europäischen Wirtschaftsraum
                                            zuvor gekürzt. Nur in Ausnahmefällen
                                            wird die volle IP-Adresse an einen
                                            Server von Google in den USA
                                            übertragen und dort gekürzt. Im
                                            Auftrag des Betreibers dieser
                                            Webseite wird Google diese
                                            Informationen benutzen, um Ihre
                                            Nutzung der Webseite auszuwerten, um
                                            Reports über die
                                            Webseitenaktivitäten
                                            zusammenzustellen und um weitere,
                                            mit der Websitenutzung und der
                                            Internetnutzung verbundene,
                                            Dienstleistungen gegenüber dem
                                            Verantwortlichen zu erbringen. Wir
                                            haben darüber hinaus die
                                            geräteübergreifende Analyse von
                                            Website-Besuchern aktiviert, die
                                            über eine sog. User-ID durchgeführt
                                            wird. Die im Rahmen von Google
                                            Analytics von Ihrem Browser
                                            übermittelte IP-Adresse wird nicht
                                            mit anderen Daten von Google
                                            zusammengeführt. Die Nutzung von
                                            Google Analytics dient dem Zweck der
                                            Analyse, Optimierung und
                                            Verbesserung unserer Website.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Rechtsgrundlage hierfür ist unser in
                                            den obigen Zwecken auch liegendes
                                            berechtigtes Interesse an der
                                            Datenverarbeitung nach Art. 6 Abs. 1
                                            S.1 lit. f) DS-GVO.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Die von uns gesendeten und mit
                                            Cookies, Nutzerkennungen (z. B.
                                            User-ID) oder Werbe-IDs verknüpften
                                            Daten werden nach Monaten
                                            automatisch gelöscht. Die Löschung
                                            von Daten, deren Aufbewahrungsdauer
                                            erreicht ist, erfolgt automatisch
                                            einmal im Monat.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Weitere Informationen zu
                                            Datennutzung bei Google Analytics
                                            finden Sie hier:{" "}
                                            <a
                                                href="https://www.google.com/analytics/terms/de.html"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.google.com/analytics/terms/de.html
                                            </a>
                                            (Nutzungsbedingungen von Analytics),{" "}
                                            <a
                                                href="https://support.google.com/analytics/answer/6004245?hl=de"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://support.google.com/analytics/answer/6004245?hl=de
                                            </a>
                                            (Hinweise zum Datenschutz bei
                                            Analytics) und Googles
                                            Datenschutzerklärung{" "}
                                            <a
                                                href="https://policies.google.com/privacy"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://policies.google.com/privacy
                                            </a>
                                            .<br />
                                            <br />
                                        </li>
                                        <li>
                                            Widerspruch und „Opt-Out“: Das
                                            Speichern von Cookies auf Ihrer
                                            Festplatte können Sie allgemein
                                            verhindern, indem Sie in Ihren
                                            Browser-Einstellungen „keine Cookies
                                            akzeptieren“ wählen. Dies kann aber
                                            eine Funktionseinschränkung unserer
                                            Angebote zur Folge haben. Sie können
                                            darüber hinaus die Erfassung der,
                                            durch das Cookie erzeugten und auf
                                            Ihre Nutzung der Website bezogenen,
                                            Daten an Google sowie die
                                            Verarbeitung dieser Daten durch
                                            Google verhindern, indem sie das
                                            unter dem folgenden Link verfügbare
                                            Browser-Plugin herunterladen und
                                            installieren:{" "}
                                            <a
                                                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                http://tools.google.com/dlpage/gaoptout?hl=de
                                            </a>
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Als Alternative zum obigen
                                            Browser-Plugin können Sie die
                                            Erfassung durch Google Analytics
                                            unterbinden, indem Sie{" "}
                                            <strong>
                                                [__hier bitte__den Analytics
                                                Opt-Out Link Ihrer Webseite
                                                einfügen]
                                            </strong>{" "}
                                            klicken. Durch den Klick wird ein
                                            „Opt-out“-Cookie gesetzt, das die
                                            Erfassung Ihrer Daten beim Besuch
                                            dieser Webseite zukünftig
                                            verhindert. Dieses Cookie gilt nur
                                            für unsere Webseite und Ihren
                                            aktuellen Browser und hat nur
                                            solange Bestand bis Sie Ihre Cookies
                                            löschen. In dem Falle müssten Sie
                                            das Cookie erneut setzen.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Die geräteübergreifende
                                            Nutzeranalyse können Sie in Ihrem
                                            Google-Account unter „Meine Daten
                                            &gt; persönliche Daten“
                                            deaktivieren.
                                            <br />
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>Google Maps</strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wir haben auf unserer Website Karten
                                            von „Google Maps“ (Google Ireland
                                            Limited, Registernr.: 368047, Gordon
                                            House, Barrow Street, Dublin 4,
                                            Irland) integriert. Damit können
                                            wirden Standort von Adressen und
                                            eine Anfahrtsbeschreibung direkt auf
                                            unserer Website in interaktiven
                                            Karten anzeigen und Ihnen die
                                            Nutzung dieses Tools ermöglichen.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Bei dem Abruf unserer Website, wo
                                            Google Maps integriert ist, wird
                                            eine Verbindung zu den Servern von
                                            Google in den USA aufgebaut. Hierbei
                                            können Ihre IP und Standort an
                                            Google übertragen werden. Zudem
                                            erhält Google die Information, dass
                                            Sie die entsprechende Seite
                                            aufgerufen haben. Dies erfolgt auch
                                            ohne Nutzerkonto bei Google. Sollten
                                            Sie in Ihren Google-Account
                                            eingeloggt sein, kann Google die
                                            obigen Daten Ihrem Account zuordnen.
                                            Wenn Sie dies nicht wünschen, müssen
                                            Sie sich bei Ihrem Google-Account
                                            ausloggen. Google erstellt aus
                                            solchen Daten Nutzerprofile und
                                            nutzt diese Daten zum Zwecke der
                                            Werbung, Marktforschung oder
                                            Optimierung seiner Websites.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Rechtsgrundlage hierfür ist unser in
                                            den obigen Zwecken auch liegendes
                                            berechtigtes Interesse an der
                                            Datenverarbeitung nach Art. 6 Abs. 1
                                            S.1 lit. f) DS-GVO.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Sie haben gegenüber Google ein
                                            Widerspruchsrecht gegen die Bildung
                                            von Nutzerprofilen. Bitte richten
                                            Sie sich deswegen direkt an Google
                                            über die unten genannte
                                            Datenschutzerklärung. Ein
                                            Opt-Out-Widerspruch hinsichtlich der
                                            Werbe-Cookies können Sie hier in
                                            Ihrem Google-Account vornehmen:
                                            <br />
                                            <a
                                                href="https://adssettings.google.com/authenticated"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://adssettings.google.com/authenticated
                                            </a>
                                            .<br />
                                            <br />
                                        </li>
                                        <li>
                                            In den Nutzungsbedingungen von
                                            Google Maps unter{" "}
                                            <a
                                                href="https://www.google.com/intl/de_de/help/terms_maps.html"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.google.com/intl/de_de/help/terms_maps.html
                                            </a>{" "}
                                            und in der Datenschutzerklärung für
                                            Werbung von Google unter{" "}
                                            <a
                                                href="https://policies.google.com/technologies/ads"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://policies.google.com/technologies/ads
                                            </a>{" "}
                                            finden Sie weitere Informationen zur
                                            Verwendung von Google Cookies und
                                            deren Werbetechnologien,
                                            Speicherdauer, Anonymisierung,
                                            Standortdaten, Funktionsweise und
                                            Ihre Rechte. Allgemeine
                                            Datenschutzerklärung von Google:{" "}
                                            <a
                                                href="https://policies.google.com/privacy"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://policies.google.com/privacy
                                            </a>
                                            .<br />
                                            <br />
                                        </li>
                                        <li>
                                            Google ist nach dem EU-US Privacy
                                            Shield zertifiziert (
                                            <a
                                                href="https://www.privacyshield.gov/EU-US-Framework"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.privacyshield.gov/EU-US-Framework
                                            </a>
                                            ) und daher verpflichtet
                                            europäisches Datenschutzrecht
                                            einzuhalten.
                                            <br />
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>Präsenz in sozialen Medien</strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Wir unterhalten in sozialen Medien
                                            Profile bzw. Fanpages, um mit den
                                            dort angeschlossenen und
                                            registrierten Nutzern zu
                                            kommunizieren und um über unsere
                                            Produkte, Angebote und
                                            Dienstleistungen zu informieren. Die
                                            US-Anbieter sind nach dem sog.
                                            Privacy-Shield zertifiziert und
                                            damit verpflichtet europäischen
                                            Datenschutz einzuhalten. Bei der
                                            Nutzung und dem Aufruf unseres
                                            Profils im jeweiligen Netzwerk durch
                                            Sie gelten die jeweiligen
                                            Datenschutzhinweise und
                                            Nutzungsbedingungen des jeweiligen
                                            Netzwerks.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Wir verarbeiten Ihre Daten, die Sie
                                            uns über diese Netzwerke senden, um
                                            mit Ihnen zu kommunizieren und um
                                            Ihre dortigen Nachrichten zu
                                            beantworten.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Die Rechtsgrundlage für die
                                            Verarbeitung der personenbezogenen
                                            Daten ist unser berechtigtes
                                            Interesse an der Kommunikation mit
                                            den Nutzern und unsere
                                            Außendarstellung zwecks Werbung
                                            gemäß Art. 6 Abs. 1 S. 1 lit. f)
                                            DS-GVO. Soweit Sie dem
                                            Verantwortlichen des sozialen
                                            Netzwerks eine Einwilligung in die
                                            Verarbeitung Ihrer personenbezogenen
                                            Daten erteilt haben, ist
                                            Rechtsgrundlage Art. 6 Abs. 1 S. 1
                                            lit. a) und Art. 7 DS-GVO.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Die Datenschutzhinweise,
                                            Auskunftsmöglichkeiten und
                                            Widerspruchmöglichkeiten (Opt-Out)
                                            der jeweiligen Netzwerke finden Sie
                                            hier:
                                            <br />
                                            <br />• <strong>
                                                Facebook
                                            </strong>{" "}
                                            (Facebook Ireland Ltd., 4 Grand
                                            Canal Square, Grand Canal Harbour,
                                            Dublin 2, Irland)
                                            Datenschutzerklärung:{" "}
                                            <a
                                                href="https://www.facebook.com/about/privacy/"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.facebook.com/about/privacy/
                                            </a>
                                            , Opt-Out:{" "}
                                            <a
                                                href="https://www.facebook.com/settings?tab=ads"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.facebook.com/settings?tab=ads
                                            </a>{" "}
                                            und{" "}
                                            <a
                                                href="http://www.youronlinechoices.com"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                http://www.youronlinechoices.com
                                            </a>
                                            , Privacy Shield:{" "}
                                            <a
                                                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
                                            </a>
                                            .<br />
                                            <br />•<strong>XING</strong> (XING
                                            AG, Dammtorstraße 29-32, 20354
                                            Hamburg, Deutschland) -
                                            Datenschutzerklärung/ Opt-Out:{" "}
                                            <a
                                                href="https://privacy.xing.com/de/datenschutzerklaerung"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://privacy.xing.com/de/datenschutzerklaerung
                                            </a>
                                            .<br />
                                            <br />•<strong>
                                                LinkedIn
                                            </strong>{" "}
                                            (LinkedIn Ireland Unlimited Company,
                                            Wilton Place, Dublin 2, Irland) –
                                            Datenschutzerklärung:{" "}
                                            <a
                                                href="https://www.linkedin.com/legal/privacy-policy"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.linkedin.com/legal/privacy-policy
                                            </a>
                                            , Cookie-Richtlinie und Opt-Out:{" "}
                                            <a
                                                href="https://www.linkedin.com/legal/cookie-policy"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.linkedin.com/legal/cookie-policy
                                            </a>
                                            , Privacy Shield der US-Firma
                                            LinkedIn Inc.:{" "}
                                            <a
                                                href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                            >
                                                https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active
                                            </a>
                                            .<br />
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>
                                        Datenschutz bei Bewerbungen und im
                                        Bewerberverfahren
                                    </strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            Bewerbungen, die auf elektronischem
                                            Wege oder per Post an den
                                            Verantwortlichen gesendet werden,
                                            werden zum Zwecke der Abwicklung des
                                            Bewerberverfahrens elektronisch oder
                                            manuell verarbeitet.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Wir weisen ausdrücklich darauf hin,
                                            dass Bewerbungsunterlagen mit
                                            „besonderen Kategorien
                                            personenbezogener Daten“ nach Art. 9
                                            DS-GVO (z.B. ein Foto, welches
                                            Rückschlüsse auf Ihre ethnische
                                            Herkunft, Religion oder Ihren
                                            Familienstand gibt), mit Ausnahme
                                            einer eventuellen Schwerbehinderung,
                                            welche Sie aus freier Entscheidung
                                            offenlegen möchten, unerwünscht
                                            sind. Sie sollen Ihre Bewerbung ohne
                                            diese Daten einreichen. Dies hat
                                            keine Auswirkungen auf Ihre
                                            Bewerberchancen.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Rechtsgrundlagen für die
                                            Verarbeitung sind Art. 6 Abs. 1 S.1
                                            lit. b) DS-GVO sowie § 26 BDSG n.F.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            Wird nach Abschluss des
                                            Bewerberverfahrens, ein
                                            Arbeitsverhältnis mit dem Bewerber /
                                            der Bewerberin eingegangen, werden
                                            die Bewerberdaten unter Beachtung
                                            einschlägiger
                                            Datenschutzvorschriften gespeichert.
                                            Wird Ihnen nach Abschluss des
                                            Bewerberverfahrens keine Stelle
                                            angeboten, so wird Ihr eingereichtes
                                            Bewerbungsschreiben samt Unterlagen
                                            6 Monate nach Versand der Absage
                                            gelöscht, um etwaigen Ansprüchen und
                                            Nachweispflichten nach AGG genügen
                                            zu können.
                                            <br />
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>
                                        Rechte der betroffenen Person
                                    </strong>
                                    <ol
                                        style={{
                                            margin: "10px 0px",
                                            padding: "15px",
                                        }}
                                    >
                                        <li>
                                            <strong>
                                                Widerspruch oder Widerruf gegen
                                                die Verarbeitung Ihrer Daten
                                                <br />
                                                <br />
                                                Soweit die Verarbeitung auf
                                                Ihrer Einwilligung gemäß Art. 6
                                                Abs. 1 S. 1 lit. a), Art. 7
                                                DS-GVO beruht, haben Sie das
                                                Recht, die Einwilligung
                                                jederzeit zu widerrufen. Die
                                                Rechtmäßigkeit der aufgrund der
                                                Einwilligung bis zum Widerruf
                                                erfolgten Verarbeitung wird
                                                dadurch nicht berührt.
                                                <br />
                                                <br />
                                                Soweit wir die Verarbeitung
                                                Ihrer personenbezogenen Daten
                                                auf die Interessenabwägung gemäß
                                                Art. 6 Abs. 1 S. 1 lit. f)
                                                DS-GVO stützen, können Sie
                                                Widerspruch gegen die
                                                Verarbeitung einlegen. Dies ist
                                                der Fall, wenn die Verarbeitung
                                                insbesondere nicht zur Erfüllung
                                                eines Vertrags mit Ihnen
                                                erforderlich ist, was von uns
                                                jeweils bei der nachfolgenden
                                                Beschreibung der Funktionen
                                                dargestellt wird. Bei Ausübung
                                                eines solchen Widerspruchs
                                                bitten wir um Darlegung der
                                                Gründe, weshalb wir Ihre
                                                personenbezogenen Daten nicht
                                                wie von uns durchgeführt
                                                verarbeiten sollten. Im Falle
                                                Ihres begründeten Widerspruchs
                                                prüfen wir die Sachlage und
                                                werden entweder die
                                                Datenverarbeitung einstellen
                                                bzw. anpassen oder Ihnen unsere
                                                zwingenden schutzwürdigen Gründe
                                                aufzeigen, aufgrund derer wir
                                                die Verarbeitung fortführen.
                                                <br />
                                                <br />
                                                Sie können der Verarbeitung
                                                Ihrer personenbezogenen Daten
                                                für Zwecke der Werbung und
                                                Datenanalyse jederzeit
                                                widersprechen. Das
                                                Widerspruchsrecht können Sie
                                                kostenfrei ausüben. Über Ihren
                                                Werbewiderspruch können Sie uns
                                                unter folgenden Kontaktdaten
                                                informieren:
                                                <br />
                                                <br />
                                                Punitech GmbH
                                                <br />
                                                Kirrlacherstr. 23
                                                <br />
                                                68753 Waghäusel Deutschland
                                                <br />
                                                Geschäftsführer Dominik Bonsch
                                                <br />
                                                Handelsregister/Nr.: HRB 719692
                                                <br />
                                                Registergericht: Mannheim
                                                <br />
                                                E-Mail-Adresse:
                                                contact@punitech.com
                                                <br />
                                            </strong>
                                            <br />
                                        </li>
                                        <li>
                                            <strong>Recht auf Auskunft</strong>
                                            <br />
                                            Sie haben das Recht, von uns eine
                                            Bestätigung darüber zu verlangen, ob
                                            Sie betreffende personenbezogene
                                            Daten verarbeitet werden. Sofern
                                            dies der Fall ist, haben Sie ein
                                            Recht auf Auskunft über Ihre bei uns
                                            gespeicherten persönlichen Daten
                                            nach Art. 15 DS-GVO. Dies beinhaltet
                                            insbesondere die Auskunft über die
                                            Verarbeitungszwecke, die Kategorie
                                            der personenbezogenen Daten, die
                                            Kategorien von Empfängern, gegenüber
                                            denen Ihre Daten offengelegt wurden
                                            oder werden, die geplante
                                            Speicherdauer, die Herkunft ihrer
                                            Daten, sofern diese nicht direkt bei
                                            Ihnen erhoben wurden.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>
                                                Recht auf Berichtigung
                                            </strong>
                                            <br />
                                            Sie haben ein Recht auf Berichtigung
                                            unrichtiger oder auf
                                            Vervollständigung richtiger Daten
                                            nach Art. 16 DS-GVO.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>Recht auf Löschung</strong>
                                            <br />
                                            Sie haben ein Recht auf Löschung
                                            Ihrer bei uns gespeicherten Daten
                                            nach Art. 17 DS-GVO, es sei denn
                                            gesetzliche oder vertraglichen
                                            Aufbewahrungsfristen oder andere
                                            gesetzliche Pflichten bzw. Rechte
                                            zur weiteren Speicherung stehen
                                            dieser entgegen.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>
                                                Recht auf Einschränkung
                                            </strong>
                                            <br />
                                            Sie haben das Recht, eine
                                            Einschränkung bei der Verarbeitung
                                            Ihrer personenbezogenen Daten zu
                                            verlangen, wenn eine der
                                            Voraussetzungen in Art. 18 Abs. 1
                                            lit. a) bis d) DS-GVO erfüllt ist:
                                            <br />
                                            • Wenn Sie die Richtigkeit der Sie
                                            betreffenden personenbezogenen für
                                            eine Dauer bestreiten, die es dem
                                            Verantwortlichen ermöglicht, die
                                            Richtigkeit der personenbezogenen
                                            Daten zu überprüfen;
                                            <br />
                                            <br />
                                            • die Verarbeitung unrechtmäßig ist
                                            und Sie die Löschung der
                                            personenbezogenen Daten ablehnen und
                                            stattdessen die Einschränkung der
                                            Nutzung der personenbezogenen Daten
                                            verlangen;
                                            <br />
                                            <br />
                                            • der Verantwortliche die
                                            personenbezogenen Daten für die
                                            Zwecke der Verarbeitung nicht länger
                                            benötigt, Sie diese jedoch zur
                                            Geltendmachung, Ausübung oder
                                            Verteidigung von Rechtsansprüchen
                                            benötigen, oder
                                            <br />
                                            <br />
                                            • wenn Sie Widerspruch gegen die
                                            Verarbeitung gemäß Art. 21 Abs. 1
                                            DS-GVO eingelegt haben und noch
                                            nicht feststeht, ob die berechtigten
                                            Gründe des Verantwortlichen
                                            gegenüber Ihren Gründen überwiegen.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>
                                                Recht auf Datenübertragbarkeit
                                            </strong>
                                            <br />
                                            Sie haben ein Recht auf
                                            Datenübertragbarkeit nach Art. 20
                                            DS-GVO, was bedeutet, dass Sie die
                                            bei uns über Sie gespeicherten
                                            personenbezogenen Daten in einem
                                            strukturierten, gängigen und
                                            maschinenlesbaren Format erhalten
                                            können oder die Übermittlung an
                                            einen anderen Verantwortlichen
                                            verlangen können.
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <strong>
                                                Recht auf Beschwerde
                                            </strong>
                                            <br />
                                            Sie haben ein Recht auf Beschwerde
                                            bei einer Aufsichtsbehörde. In der
                                            Regel können Sie sich hierfür an die
                                            Aufsichtsbehörde insbesondere in dem
                                            Mitgliedstaat ihres Aufenthaltsorts,
                                            ihres Arbeitsplatzes oder des Orts
                                            des mutmaßlichen Verstoßes wenden.
                                            <br />
                                            <br />
                                        </li>
                                    </ol>
                                    <br />
                                    <strong>Datensicherheit</strong>
                                    <p>
                                        Um alle personenbezogen Daten, die an
                                        uns übermittelt werden, zu schützen und
                                        um sicherzustellen, dass die
                                        Datenschutzvorschriften von uns, aber
                                        auch unseren externen Dienstleistern
                                        eingehalten werden, haben wir geeignete
                                        technische und organisatorische
                                        Sicherheitsmaßnahmen getroffen. Deshalb
                                        werden unter anderem alle Daten zwischen
                                        Ihrem Browser und unserem Server über
                                        eine sichere SSL-Verbindung
                                        verschlüsselt übertragen.
                                    </p>
                                    <br />
                                    <br />
                                    <strong>Stand: 24.06.2019</strong>
                                    <p>
                                        Quelle:{" "}
                                        <a href="https://www.juraforum.de/impressum-generator/">
                                            Muster-Datenschutzerklärung von
                                            JuraForum.de
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ImprintPage;
